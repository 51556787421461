import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import {
  Heading,
  Text,
  Box,
  Container
} from '@chakra-ui/react'
import { components  } from '../components/Slices';
import { PrismicRichText, SliceZone } from '@prismicio/react'
import HomepageSlider from "../components/HomepageSlider/HomepageSlider"
import HomepageTabs from "../components/HomepageTabs/HomepageTabs"
import NewsletterSignup from "../components/Slices/NewsletterSignup";



const IndexPage = ({ data, location }) => {
  const doc = data.prismicHomepage.data;

  console.log(doc)
  
    return (
    <Layout>
      <HomepageSlider 
        slides={doc.homepage_slider}
      />
      <HomepageTabs 
        tab1Title={doc.homepage_tab_1_title.text}
        tab1Content={doc.homepage_tab_1_content.richText}
        tab2Title={doc.homepage_tab_2_title.text}
        tab2Content={doc.homepage_tab_2_content.richText}
        tab3Title={doc.homepage_tab_3_title.text}
        tab3Content={doc.homepage_tab_3_content.richText}
      />
      <Box>
        <SliceZone slices={doc.body} components={components} context={location} />
      </Box>
    </Layout>
  )
}

export const query = graphql`
{
  prismicHomepage {
    data {
      seo_title
      homepage_tab_3_title {
        text
      }
      homepage_tab_3_content {
        richText
      }
      homepage_tab_2_title {
        text
      }
      homepage_tab_2_content {
        richText
      }
      homepage_tab_1_title {
        text
      }
      homepage_tab_1_content {
        richText
      }
      homepage_slider {
        subtitle {
          text
        }
        headline {
          text
        }
        slider_image {
          alt
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
        mobile_slider_image {
          alt
          localFile {
            childImageSharp {
              gatsbyImageData(
                height: 800
                width: 600
                placeholder: BLURRED
                transformOptions: {cropFocus: CENTER}
              )
            }
          }
        }
        slider_link_label
        slider_link {
          url
        }
        slider_link_label_2
        slider_link_2 {
          url
        }
      }
      body {
        ... on PrismicHomepageDataBodySponsors {
          id
          slice_type
          primary {
            sponsors_title {
              text
            }
            sponsors_subtitle {
              text
            }
            sponsors {
              document {
                ... on PrismicSponsors {
                  id
                  data {
                    sponsor {
                      sponsor_logo {
                        alt
                        localFile {
                          childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                          }
                        }
                      }
                      sponsor_link {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on PrismicHomepageDataBodyDowntownEvents {
          id
          slice_type
          primary {
            button_label
            justify_image
            button_link {
              url
              target
            }
            event_content {
              richText
            }
            event_logo {
              alt
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
            image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(aspectRatio: 1, layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
          }
          items {
            event_fact_title {
              text
            }
            event_fact_stat {
              text
            }
          }
        }
        ... on PrismicHomepageDataBodyEventsCarousel {
          id
          slice_type
          primary {
            carousel_title {
              text
            }
            show_events_carousel
          }
        }
        ... on PrismicHomepageDataBodyNewsletterSignup {
          id
          slice_type
          primary {
            newsletter_signup_title {
              text
            }
            newsletter_signup_subtitle {
              text
            }
            newsletter_signup_button_label
            newsletter_signup_background_image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
          }
        }
        ... on PrismicHomepageDataBodyCallToAction {
          id
          slice_type
          primary {
            link_label
            link {
              url
            }
            call_to_action_content {
              richText
            }
            call_to_action_title {
              text
            }
            call_to_action_image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
          }
        }
        ... on PrismicHomepageDataBodyRichText {
          id
          slice_type
          items {
            content {
              richText
            }
          }
        }
      }
    }
  }
}
`

export default IndexPage

export const Head = ({ data }) => <title>{data.prismicHomepage.data.seo_title}</title>
